import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Schedule from './components/Schedule';
import About from './components/About';
import Venue from './components/Venue';
import Ignite from './components/Ignite';
import Speaker from './components/Speakers';
import Contact from './components/Contact';
import Footer from './components/Footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div>
      <Navbar/>
      <ToastContainer/>
      <Hero/>
      <Schedule/>
    </div>
  );
}

export default App;
