import React, { useState, useEffect} from 'react'
import { Link } from 'react-scroll'
import { Link as Linkto } from 'react-router-dom'
import Logo from './images/de_events_logo.png'
import { Button } from '@material-tailwind/react'
import './Navbar.css'

const Navbar = () => {

    const [isOpen, setIsopen] = useState(false);

    const ToggleSidebar = () => {
        isOpen === true ? setIsopen(false) : setIsopen(true);
    }

    const [navBackground, setNavBackground] = useState('appBarTransparent')
    const navRef = React.useRef()
    navRef.current = navBackground
    useEffect(() => {
        const handleScroll = () => {
            const show = window.scrollY > 90
            if (show) {
                setNavBackground('ease-in duration-300 bg-black w-full fixed z-0 top-0 left-0')
            } else {
                setNavBackground('ease-in duration-300 bg-black w-full fixed z-0 top-0 left-0')
            }
        }
        document.addEventListener('scroll', handleScroll)
        return () => {
            document.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return (
    <div className='header'>
        <nav className="ease-in duration-300 bg-white w-full fixed z-0 top-0 left-0">
            <div className="xl:max-w-screen-2xl flex flex-wrap items-center justify-between mx-auto p-4">
            <Link spy={true} smooth={true} duration={500} to="hero" className="cursor-pointer flex items-center">
                <img src={Logo} className="h-10 lg:h-12 lg:h-22 lg:mr-3" alt="De Events" />
            </Link>
            <div className="flex order-2">
            <Linkto to="form"  className="deeventcolorbg hover:bg-red-700 font-medium rounded-lg text-sm py-3 px-2 text-white mr-2 text-center">REGISTER</Linkto>
        </div>
            </div>
        </nav>
       
    </div>
  )
}

export default Navbar
