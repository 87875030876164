import React, { useRef, useState, useEffect} from 'react'
import Modal from 'react-modal';
import { Link } from 'react-router-dom'
import { collection, addDoc } from "firebase/firestore";
import {db} from '../firebase';
import { useNavigate} from 'react-router-dom'
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import tShirtSizeChart from './images/tshirt size chart.png'
import tankTopSizeChart from './images/tank top size chart.jpg'
import emailjs from '@emailjs/browser';
import { Select, Option } from "@material-tailwind/react";
import background from './images/AIA Website BG.png'
import aia from './images/de_events_logo.png'
import { Fade } from "react-awesome-reveal";

export const Test = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const myStyle = {
        backgroundImage: `url(${
            background
        })`,
        height: "100vh",
        marginTop: "-70px",
        fontSize: "50px",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
    };
      

    const [modalIsOpen, setIsOpen] = useState(false);
    const [openWhich, setOpenWhich] = useState()
    const [locallyBased, setLocallyBased] = useState(false);

    function closeModal() {
        setIsOpen(false)
        setOpenWhich("")
    }
    
    const form = useRef();

    const navigate = useNavigate();

    var currentdate = new Date(); 
    var datetime =  currentdate.getDate() + "/"
                    + (currentdate.getMonth()+1)  + "/" 
                    + currentdate.getFullYear() + " @ "  
                    + currentdate.getHours() + ":"  
                    + currentdate.getMinutes() + ":" 
                    + currentdate.getSeconds();

    const colRef = collection(db, 'aiadinneranddance')
    const [formData, setFormData] = useState({
        salutation: 'Mr',
        timestamp: datetime
    })

    function makeNameNice(firstName, lastName, displayName, country, temail) {
        try {
            const firstNameList = firstName.split(" ")
            const lastNameList = lastName.split(" ")
            const displayNameList = displayName.split(" ")
            const countryList = country.split(" ")

            for (let i = 0; i < firstNameList.length; i++) {
                if (firstNameList[i] === '') {

                } else {
                    firstNameList[i] = firstNameList[i].toLowerCase()
                    firstNameList[i] = firstNameList[i][0].toUpperCase() + firstNameList[i].substr(1);
                }
            }
            for (let i = 0; i < lastNameList.length; i++) {
                if (lastNameList[i] === '') {

                } else {
                    lastNameList[i] = lastNameList[i].toLowerCase()
                    lastNameList[i] = lastNameList[i][0].toUpperCase() + lastNameList[i].substr(1);
                }
            }
            for (let i = 0; i < displayNameList.length; i++) {
                if (displayNameList[i] === '') {

                } else {
                    displayNameList[i] = displayNameList[i].toLowerCase()
                    displayNameList[i] = displayNameList[i][0].toUpperCase() + displayNameList[i].substr(1);
                }
            }

            for (let i = 0; i < countryList.length; i++) {
                if (countryList[i] === '') {

                } else {
                    countryList[i] = countryList[i].toLowerCase()
                    countryList[i] = countryList[i][0].toUpperCase() + countryList[i].substr(1);
                }
            }

            let doneFirstName = firstNameList.join(" ")
            let doneLastName = lastNameList.join(" ")
            let doneDisplayName = displayNameList.join(" ")
            let doneEmail = temail.toLowerCase()
            let doneCountry = countryList.join(" ")
            formData.email = doneEmail
            formData.displayName = doneDisplayName
            formData.country = doneCountry
            formData.firstName = doneFirstName
            formData.lastName = doneLastName
            form.current.displayName.value = doneDisplayName
        } catch (err){
            console.log('fail')
            console.log(err)
            return 'fail'
        }
    }
    
    async function sendEmail(e) {
        e.preventDefault()
        addDoc(colRef, {formData})   

        try {
            try {   
                emailjs.sendForm('service_dq7nbhr', 'template_ckx0m8e', form.current, 'r04-bQl4Di3WPya_p')
                .then((result) => {
                    console.log(result.text);
                }, (error) => {
                    console.log(error.text);
                })
            } catch(e) {
                console.log(e)
                toast.error('Registration was unsuccessful, please try again.', {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: 0,
                    theme: "light",
                    })
                return
            }
        } catch(e) {
            console.log(e)
            toast.error('Registration was unsuccessful, please try again.', {
                position: "top-right",
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: 0,
                theme: "light",
                })
            return
        }

        if (formData.attending === "No") {
                toast.success('Thank you for your response. We hope to see you at the next event!', {
                    position: "top-right",
                    autoClose: false,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: 0,
                    theme: "light",
                    })
                    window.scrollTo(0, 0)
        } else {
            toast.success('Thank you for registering! A confirmation email will be sent to you shortly!', {
                position: "top-right",
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: 0,
                theme: "light",
                })
                window.scrollTo(0, 0)
        }

  };

  const handleOpen = (e) => {
    setIsOpen(true)
    setOpenWhich(e.target.value)
  }

  function HandleModal () {
    if (openWhich === 'tShirt') {
        return <div className='text-center'><img className='pb-8 w-full' src={tShirtSizeChart} alt=""/><button className="text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg  px-5 py-2.5" onClick={closeModal}>Close</button></div>
    } else if (openWhich === 'tankTop') {
        return <div className='text-center'><img className='pb-8' src={tankTopSizeChart} alt=""/><button className="text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg  px-5 py-2.5" onClick={closeModal}>Close</button></div>
    } else {
        return <div><p className='text-center text-lg font-bold py-2'>Terms and Conditions</p>
        <p className='py-4  text-justify'>“By submitting on this platform, I consent for Synonym Private Limited, STT GDC Pte Ltd, and associated parties (including third party service providers and representatives) (STT Persons) to collect, use, disclose, store, retain and/or process (“Use”) my personal data and information in the manner and for the purposes described in their respective personal data policies, <a className='text-blue-600 hover:underline' href="https://www.sttelemediagdc.com/pdpa" rel="noreferrer" target="_blank">https://www.sttelemediagdc.com/pdpa</a>, and in particular for the purposes of processing, servicing and managing my orders and general administration in connection with the foregoing and contacting me at the contacts that I have provided. I confirm the accuracy of the information that I have furnished and further confirm that where I have furnished personal data of other individuals, I have obtained consent from such individuals to disclose such information, except to the extent that such consent is not  under relevant laws. I will indemnify STT Persons for any loss or damage that they may sustain from or in connection with the use of the information that I have furnished and will not hold them liable for any loss or damage that may be incurred by me. My attendance and participation in this event is subject to my availability and all work exigencies.”</p>
        <div className='py-4 text-center'>
        <button className="text-white bg-red-600 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg  px-5 py-2.5" onClick={closeModal}>Close</button>
        </div></div>
    }
  }

  const [isAttending, setIsAttending] = useState(true)
  const [isOthers, setIsOthers] = useState(false)

  const handleAttending = (bool) => {
    setIsAttending(bool)
  }

  const handleAllergy = (bool) => {
    setIsOthers(bool)
  }
    
    return (
        <div className='pb-20'>
                         <nav className="m-2 flex px-4 py-3 text-gray-700 border border-gray-200 rounded-lg bg-gray-50" aria-label="Breadcrumb">
        <ol className="inline-flex items-center space-x-1 md:space-x-3">
            <li className="inline-flex items-center">
            <Link to="/" className=" inline-flex items-center text-sm font-semibold text-gray-700 hover:deeventcolor">
                <svg aria-hidden="true" className="w-4 h-4 mr-2" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path></svg>
                Home
            </Link>
            </li>
            <li aria-current="page">
            <div className="flex items-center">
                <svg aria-hidden="true" className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
                <span className=" ml-1 text-sm font-semibold deeventcolor md:ml-2">Registration</span>
            </div>
            </li>
        </ol>
        </nav>
            <ToastContainer/>
            <Fade duration={2000}>
            <form ref={form} onSubmit={sendEmail} className='grid grid-cols-12'>
                <div className='col-span-2 md:col-span-3'>
                </div>

                <div className='col-span-8 md:col-span-6 w-full'>
                <div class="container  mx-auto ">
                    <img className='w-9/12 lg:w-8/12 py-12' src={aia}/>
                </div>
                    <div>
                        <label className="pt-2">Name<span className='text-red-600'>*</span></label>
                        <br/>
                        <input name="name" type="text" onInput={(e) => setFormData({...formData, name: e.target.value})} value={formData.name} className="w-full bg-white border-red-600 border-1"/>
                    </div>
                    <div className="pt-3">
                        <label>Division<span className='text-red-600'>*</span></label>
                        <br/>
                        <select name="division" type="text" onChange={(e) => setFormData({...formData, division: e.target.value})} value={formData.division} className="w-full bg-white border-red-600 border-1">
                            <option value='option1'>Option 1</option>
                            <option value='option2'>Option 2</option>
                            <option value='option2'>Option 3</option>
                        </select>
                    </div>
                    <div className="pt-3">
                        <label>Email<span className='text-red-600'>*</span></label>
                        <br/>
                        <input name="email" type="text" onInput={(e) => setFormData({...formData, email: e.target.value})} value={formData.email} className="w-full bg-white border-red-600 border-1"/>
                    </div>
                    <div className='pt-3'>
                        <label>Are you attending?<span className='text-red-600'>*</span></label>
                        <div className="flex items-center mb-4">
                            <input onClick={() => handleAttending(true)} onInput={(e) => {setFormData({...formData, attending: e.target.value}); console.log(e.tareg)}} id="attending-option-1" type="radio" name="attending" value="Yes" className="w-4 h-4 border-gray-300 focus:ring-1 focus:ring-red-600  text-red-600  " required/>
                            <label htmlFor="attending-option-1" className="ml-2  font-medium text-gray-900 ">
                            Yes
                            </label>
                            <input onClick={() => {handleAttending(false); handleAllergy(false)}} onInput={(e) => setFormData({...formData, attending: e.target.value})} id="attending-option-2" type="radio" name="attending" value="Yes" className="ml-4 w-4 h-4 border-gray-300 focus:ring-1 focus:ring-red-600  text-red-600 " required/>
                            <label htmlFor="attending-option-2" className="ml-2  font-medium text-gray-900 ">
                            No
                            </label>
                        </div>
                    </div>
                    { isAttending ? <div>
                    <div className='pt-3'>
                        <label>Please select your preffered menu:<span className='text-red-600'>*</span></label>
                        <fieldset>
                        <div className="items-center mb-4">
                            
                            <input onInput={(e) => setFormData({...formData, dietary: e.target.value})} id="dietary-option-1" type="radio" name="dietary" value="Yes" className="w-4 h-4 border-gray-300 focus:ring-1 focus:ring-red-600  text-red-600  " required/>
                            <label htmlFor="dietary-option-1" className="ml-2">
                            Chinese Set
                            </label>
                            <br/>
                            <input onInput={(e) => setFormData({...formData, dietary: e.target.value})} id="dietary-option-2" type="radio" name="dietary" value="Yes" className="w-4 h-4 border-gray-300 focus:ring-1 focus:ring-red-600  text-red-600 " required/>
                            <label htmlFor="dietary-option-2" className="ml-2 ">
                            Muslim Set
                            </label>
                            <br/>
                            <input onInput={(e) => setFormData({...formData, dietary: e.target.value})} id="dietary-option-3" type="radio" name="dietary" value="Yes" className="w-4 h-4 border-gray-300 focus:ring-1 focus:ring-red-600  text-red-600 " required/>
                            <label htmlFor="dietary-option-3" className="ml-2">
                            Vegetarian Set
                            </label>
                            <br/>
                            <input onInput={(e) => setFormData({...formData, dietary: e.target.value})} id="dietary-option-4" type="radio" name="dietary" value="Yes" className="w-4 h-4 border-gray-300 focus:ring-1 focus:ring-red-600  text-red-600 " required/>
                            <label htmlFor="dietary-option-4" className="ml-2">
                            Indian Vegetarian Set
                            </label>
                        </div>
                        </fieldset>
                    </div>
                    <div className='pt-3'>
                        <label>Food Allergies (If any)</label>
                        <fieldset>
                        <div className="items-center mb-4">
                            <input onClick={() => handleAllergy(false)} onInput={(e) => setFormData({...formData, allergy: e.target.value})} id="allergy-option-1" type="radio" name="allergy" value="Yes" className="w-4 h-4 border-gray-300 focus:ring-1 focus:ring-red-600  text-red-600  " required/>
                            <label htmlFor="allergy-option-1" className="ml-2 ">
                            Shell Fish
                            </label>
                            <br/>
                            <input onClick={() => handleAllergy(false)} onInput={(e) => setFormData({...formData, allergy: e.target.value})} id="allergy-option-2" type="radio" name="allergy" value="Yes" className="w-4 h-4 border-gray-300 focus:ring-1 focus:ring-red-600  text-red-600 " required/>
                            <label htmlFor="allergy-option-2" className="ml-2">
                            Nuts
                            </label>
                            <br/>
                            <input onClick={() => handleAllergy(true)} onInput={(e) => setFormData({...formData, allergy: e.target.value})} id="allergy-option-3" type="radio" name="allergy" value="Yes" className="w-4 h-4 border-gray-300 focus:ring-1 focus:ring-red-600  text-red-600 " required/>
                            <label htmlFor="allergy-option-3" className="ml-2">
                                Others (Please specify)
                            </label>
                        </div>
                        </fieldset>
                    </div></div>: <></>}
 
                    <div>
                        {isAttending && isOthers ? <input name="firstName" type="text" onInput={(e) => setFormData({...formData, allergyremarks: e.target.value})} value={formData.firstName} className="w-full bg-white border-red-600 border-1"/> : <></>}
                    </div>
                    <div className='mx-auto text-center'>
                    <button className='mt-6 w-40 mx-auto deeventcolorbg py-2 px-3 text-white'>Submit</button>
                    </div>
               </div>

                <div className='col-span-2 md:col-span-3'>
                </div>
            </form>
            </Fade>
        </div>
);
};

export default Test
