import React from 'react'
import { Fade } from "react-awesome-reveal";
import './Hero.css'
import HeroImg from './images/OrangeTee Elevate.jpeg'
import deevents from './images/test.png'

const Hero = () => {
  return (
<div className='mx-auto' id='hero'>
        {/*<div dangerouslySetInnerHTML={{ __html: `
        <video
          loop
          muted
          autoplay
          playsinline
          src="${backgroundVideo}"
        />,
  ` }}></div>*/}
  <img src={HeroImg} className='heroImg'/>
      <div className='contents mx-auto pt-20'>
        <Fade triggerOnce duration={2000}>
          <img className='mx-auto' src={deevents} alt=''/>
        </Fade>
        </div>
    </div>
  )
}

export default Hero
